import * as React from "react";
import * as LabelPrimitive from "@radix-ui/react-label";
import { Slot } from "@radix-ui/react-slot";

import { cn } from "@/lib/utils.ts";
import { Label } from "@/components/ui/label";

const Form = ({
    children,
    className,
    ...props
}: React.HTMLProps<HTMLFormElement>) => {
    return (
        <form {...props} className={cn("space-y-4", className)}>
            {children}
        </form>
    );
};

const useFormField = () => {
    const itemContext = React.useContext(FormItemContext);
    const { id, name, error } = itemContext;

    return {
        id,
        name,
        formItemId: `${id}-form-item`,
        formDescriptionId: `${id}-form-item-description`,
        formMessageId: `${id}-form-item-message`,
        error,
    };
};

type FormItemContextValue = {
    id: string;
    name: string;
    error?: string;
};

const FormItemContext = React.createContext<FormItemContextValue>(
    {} as FormItemContextValue,
);

type FormItemProps = React.HTMLAttributes<HTMLDivElement> & {
    name: string;
    error?: string;
};

const FormItem = React.forwardRef<HTMLDivElement, FormItemProps>(
    ({ className, ...props }, ref) => {
        const id = React.useId();
        const name = props.name;
        const error = props.error;

        return (
            <FormItemContext.Provider value={{ id, name, error }}>
                <div
                    ref={ref}
                    className={cn("flex flex-col space-y-2", className)}
                    {...props}
                />
            </FormItemContext.Provider>
        );
    },
);

FormItem.displayName = "FormItem";

const FormLabel = React.forwardRef<
    React.ElementRef<typeof LabelPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>
>(({ className, ...props }, ref) => {
    const { formItemId } = useFormField();

    return (
        <Label
            ref={ref}
            className={cn(className)}
            htmlFor={formItemId}
            {...props}
        />
    );
});
FormLabel.displayName = "FormLabel";

const FormControl = React.forwardRef<
    React.ElementRef<typeof Slot>,
    React.ComponentPropsWithoutRef<typeof Slot>
>(({ ...props }, ref) => {
    const { error, formItemId, formDescriptionId, formMessageId, name } =
        useFormField();

    const moreProps = {
        name,
    };

    return (
        <Slot
            ref={ref}
            id={formItemId}
            aria-describedby={
                !error
                    ? `${formDescriptionId}`
                    : `${formDescriptionId} ${formMessageId}`
            }
            aria-invalid={!!error}
            {...props}
            {...moreProps}
        />
    );
});
FormControl.displayName = "FormControl";

const FormDescription = React.forwardRef<
    HTMLParagraphElement,
    React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => {
    const { formDescriptionId } = useFormField();

    return (
        <p
            ref={ref}
            id={formDescriptionId}
            className={cn("text-sm text-muted-foreground", className)}
            {...props}
        />
    );
});
FormDescription.displayName = "FormDescription";

const FormMessage = React.forwardRef<
    HTMLParagraphElement,
    React.HTMLAttributes<HTMLParagraphElement>
>(({ className, children, ...props }, ref) => {
    const { error, formMessageId } = useFormField();
    const body = error ? String(error) : children;

    if (!body) {
        return null;
    }

    return (
        <p
            ref={ref}
            id={formMessageId}
            className={cn("text-sm font-medium text-destructive", className)}
            {...props}
        >
            {body}
        </p>
    );
});
FormMessage.displayName = "FormMessage";

export {
    useFormField,
    Form,
    FormItem,
    FormLabel,
    FormControl,
    FormDescription,
    FormMessage,
};
